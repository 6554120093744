import {BillingDetails} from "myfitworld-model";
import {firestore} from "../firebase";
import firebase from "firebase";
import {SUBSCRIPTION_ACTIVE,SUBSCRIPTION_CREATED_NEW} from "../components/payment/utils";

const FUNCTIONS_BASE_URL = process.env.REACT_APP_FUNCTIONS_BASE_URL;

export const getOrganizationBillingDetails = (
  orgId: string
): Promise<BillingDetails | null> =>
  new Promise((resolve, reject) => {
    return firestore.collection('billingDetails').where(firebase.firestore.FieldPath.documentId(), '==', orgId).get().then((snapshot) => {
      snapshot.empty ? resolve(null) : resolve({id: snapshot.docs[0].id, ...snapshot.docs[0].data()} as BillingDetails);
    }).catch((err) => {
      console.log(err);
      reject(null);
    })
  });

export const setOrganizationBillingDetails = (
  orgId: string,
  billingDetails: any
): Promise<BillingDetails | null> =>
  new Promise((resolve, reject) => {
    return firestore.collection('billingDetails').doc(orgId).update(billingDetails).then((snapshot) => {
    }).catch((err) => {
      console.log(err);
      reject(null);
    })
  });

export const createOrganizationBillingDetails = async (orgId: string, billingDetails: any) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/billingDetails/update/${orgId}`, {
    method: 'PUT',
    body: JSON.stringify(billingDetails),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return response;
}

export const updateOrganizationCardDetails = async (orgId: string, billingDetails: any) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/billingDetails/card/update/${orgId}`, {
    method: 'PUT',
    body: JSON.stringify(billingDetails),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return response;
}

export const updateOrganizationPlan = async (orgId: string, billingDetails: any) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/billingDetails/plan/update/${orgId}`, {
    method: 'PUT',
    body: JSON.stringify(billingDetails),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return response;
}

export const updateOrganizationBillingAddress = async (orgId: string, billingDetails: any) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/billingDetails/address/update/${orgId}`, {
    method: 'PUT',
    body: JSON.stringify(billingDetails),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return response;
}

export const listInvoices = async (customerID: string, subscriptionID: string) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/listInvoices/customer/${customerID}/subscription/${subscriptionID}`, {
    method: 'GET',
    headers: {
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return response;
}

export const listInvoicesCustomer = async () => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/listInvoices/customer`, {
    method: 'GET',
    headers: {
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return response;
}


export const getUpcomingInvoice = async (customerID: string, subscriptionID: string) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/upcomingInvoice/customer/${customerID}/subscription/${subscriptionID}`, {
    method: 'GET',
    headers: {
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return response;
}

export const cancelSubscription = async (orgID: string, subscriptionID: string) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/cancelSubscription/organization/${orgID}/subscription/${subscriptionID}`, {
    method: 'DELETE',
    headers: {
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return response;
}

export const cancelStripeConnectSubscription = async () => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/stripeConnect`, {
    method: 'DELETE',
    headers: {
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return response;
}

export const cancelSubscriptionAndResetClients = async(organizationID: string, subscriptionID: string) =>{
      if(!(organizationID && subscriptionID)){return}
      try{
        let res = await cancelSubscription(organizationID, subscriptionID).then((res)=>{return res}, (err)=>{console.log(err)})
            return res;
      } catch (error) {
        console.log(error)
        return false;
    }
}

export const listCoupons = async () => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/coupons/list`, {
    method: 'GET',
    headers: {
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return response;
}

export const isOrganizationOnTheFreePlanAndHasTwoClients = async (selectedOrganizationId: string, invitation?:boolean, getIsOrgOnTheFreePlan?:boolean):Promise<boolean> =>{
    // Check if the organization is on the free plan
    // and if it has 2 clients set
    if(getIsOrgOnTheFreePlan){
        const rd = await firestore.collection("organizations").doc(selectedOrganizationId).get().then((doc)=>{
          if(doc.exists){
            console.log(doc.data()?.free)
            return doc.data()?.free?false:true; // if true we will count it as being limited
          }
          return true;
        })
        if ( !rd ){return rd;}
    }
    return await getOrganizationBillingDetails(selectedOrganizationId).then(
      (data)=>{
        const quantity = data?.stripe?.quantity || 0;
        const state = data?.state;
        const invoiceState = data?.invoiceState;
        if((
            state === SUBSCRIPTION_CREATED_NEW  &&
            invoiceState === SUBSCRIPTION_ACTIVE
          )){
          return false;
        } else if(invitation?quantity > 2: quantity >= 2){
          return true
        }
        return false;
      }, ()=>false
    );
  }

  export const createNewSubscription = async (orgId: string, stipePriceId:string) => {
    const token = await firebase.auth().currentUser?.getIdToken();
    const response = await fetch(`${FUNCTIONS_BASE_URL}/billingDetails/create-new-subscription`, {
      method: 'POST',
      body: JSON.stringify({
        orgId:orgId,
        priceId:stipePriceId
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? 'Bearer ' + token : ''
      }
    });
    return response;
  }

export const createRebrandSubscription = async (
  customerId: string,
  rebrandPriceId: string,
  orgId: string,
  paymentType: string,
  paymentMethodId: string,
  userAdminEmail: string,
  orgName: string
) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  
  return await fetch(`${FUNCTIONS_BASE_URL}/billingDetails/create-rebrand-subscription`, {
    method: 'POST',
    body: JSON.stringify({
      customerId: customerId,
      rebrandPriceId: rebrandPriceId,
      orgId: orgId,
      paymentType: paymentType,
      paymentMethodId: paymentMethodId,
      userAdminEmail: userAdminEmail,
      orgName: orgName
    }),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
}

export const cancelRebrandSubscription = async (subscriptionId: string, orgId: string, paymentType: string, subscriptionScheduleId: string) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  return await fetch(`${FUNCTIONS_BASE_URL}/billingDetails/cancel-rebrand-subscription`, {
    method: 'POST',
    body: JSON.stringify({subscriptionId, orgId, paymentType, subscriptionScheduleId}),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
}

export const manageRebrandSubscription = async (subscriptionId: string, rebrandPriceId: string, organizationId: string, paymentInterval: string) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  
  return await fetch(`${FUNCTIONS_BASE_URL}/billingDetails/update-rebrand-subscription`, {
    method: 'POST',
    body: JSON.stringify({
      subscriptionId,
      rebrandPriceId,
      organizationId,
      paymentInterval
    }),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
}

export const stripeConnectLoginURL = async (organizationStripeConnectId: undefined | string) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/stripeConnectLoginURL`, {
    method: 'POST',
    body: JSON.stringify({
      stripeConnectID: organizationStripeConnectId
    }),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });

  return await response.json();
}

export const stripeConnectProduct = async (data: {
  type: string,
  id: string | undefined,
  locked: boolean,
  price_in: number | undefined,
  enName: string,
  stripeProduct?: string
}) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/stripeConnect/products`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });

  return await response.json();
}

export const stripeConnectPaymentLink = async (data: {
  stripeAccountId: string,
  stripeProductId: string,
  contentId: string,
  contentType: "workouts" | "programs"
}) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/stripeConnect/paymentLink`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });

  return await response.json();
}

export const stripeConnectStartSubscription = async () => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/stripeConnect/start-subscription`, {
    method: 'GET',
    headers: {
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return  await response.json();
}
